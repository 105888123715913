import React, { useState, useEffect, useRef, useMemo } from "react";
import _ from "lodash";
import {
  CAPACITY_MANAGEMENT_TYPES,
  CAPACITY_TYPES,
  LOCAL_STORAGE,
  PIXEL_STREAMING_CONTAINER_COUNTS,
  REGIONS_NAMES,
  RESOLUTION_TYPES,
} from "Constants/global.constants";
import { apiGenerator, classnames, useAPI } from "Utils";
import { DivButton, Dropdown, HoverableTooltip, Icon, Modal, NumberInput, Switch, Table, TextButton } from "UI";
import {
  approveCapacityWarningConsent,
  getAppCapacitiesAPI,
  getCurrentPlanAPI,
  getVendorAccountAPI,
} from "Actions/AppStreaming.actions";
import { useSelector, useDispatch } from "react-redux";
import ChooseRegion from "Components/AppStreaming/Modals/CreateStreamModal/ChooseRegion.component";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import CapacitySelection from "Components/AppStreaming/Modals/CapacitySelection/CapacitySelection.component";
import { snakeToCamel } from "Utils/Helpers/functions.helpers";
import StreamCapacityTypeModal from "Components/AppStreaming/Modals/StreamCapacityTypeModal/StreamCapacityTypeModal.component";
import { useQuery } from "@tanstack/react-query";
import {
  JOYSTICK_OPTIONS,
  MAX_CAPACITY_BY_VENDOR_TYPE,
  OPERATING_SYSTEMS,
  SOUND_AND_MICROPHONE_OPTIONS,
  KEYBOARD_LAYOUTS,
  STREAM_TYPES,
  AUTOMATED_AREAS,
} from "Constants/AppStreaming.constants";
import { Translate } from "react-localize-redux";
import useLocalStorage from "Utils/Hooks/useLocalStorage";
import SubscriptionRequestModal from "Components/AppStreaming/Modals/SubscriptionRequestModal/SubscriptionRequestModal.component";
import { useHistory, useLocation } from "react-router-dom/cjs/react-router-dom";

import "./BasicsStep.styles.scss";

const BasicsStep = ({
  initialStreamConfigs,
  streamConfigs,
  setStreamConfigs,
  streamType,
  openSetupModal,
  translate,
}) => {
  const childStream = streamType === STREAM_TYPES.child;
  const {
    capacities,
    applicationId,
    averageInstallationDuration,
    canActivatePixelStreaming,
    os,
    canActivateRenderStreaming,
    useSatelliteRegions,
    containerCount,
    capacityManagementType,
  } = streamConfigs;
  const location = useLocation();
  const history = useHistory();
  const capacityType = streamConfigs.capacities?.[0]?.capacityType;
  const [newCapacityType, setNewCapacityType] = useState(capacityType);
  const [individualCapacity, setIndividualCapacity] = useState(false);
  const [showChooseRegionModal, setShowChooseRegionModal] = useState(false);
  const [showCapacityTypeModal, setShowCapacityTypeModal] = useState(false);
  const [showAdvancedConfigurations, setShowAdvancedConfigurations] = useLocalStorage(
    LOCAL_STORAGE.showAdvancedConfigurations,
    false,
  );
  const [showCapacityWarning, setShowCapacityWarning] = useState(false);
  const [capacityWarningConsent, setCapacityWarningConsent] = useState(false);
  const [pixelStreamingContainerEnabled, setPixelStreamingContainerEnabled] = useState(false);
  const [showStreamCapacityTypeModal, setShowStreamCapacityTypeModal] = useState(false);
  const [showSubscriptionRequestModal, setShowSubscriptionRequestModal] = useState(false);

  const capacityWarningConsentApproved = useSelector(
    (state) => state.appStreaming.getVendorAccountCTX?.data?.attributes?.capacity_warning_consent,
  );

  const { data: applicationData } = useQuery({
    queryKey: ["streamConfigsApp", streamConfigs.applicationId],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_APPLICATION(streamConfigs.applicationId));
    },
  });

  useEffect(() => {
    if (openSetupModal) {
      setShowStreamCapacityTypeModal(true);

      setTimeout(() => {
        history.replace({
          ...location,
          state: {
            ...location.state,
            openSetupModal: false,
          },
        });
      }, 1000);
    }
  }, [openSetupModal]);

  const application = applicationData?.data;

  const dispatch = useDispatch();
  const initialIndividualCapacitySetRef = useRef(false);

  const isLinux = os === OPERATING_SYSTEMS.LINUX;

  const alwaysOnTotalCapacityInputRef = useRef(null);

  const fastConnection = capacityManagementType === CAPACITY_MANAGEMENT_TYPES.fast_connection;

  const showAdvancedConfigsButton =
    (capacityType !== CAPACITY_TYPES.always_on || capacities?.length > 1) && !fastConnection;

  useEffect(() => {
    if (!showAdvancedConfigurations && alwaysOnTotalCapacityInputRef.current) {
      alwaysOnTotalCapacityInputRef.current.focus();
    }
  }, [showCapacityTypeModal]);

  useEffect(() => {
    if (!showAdvancedConfigsButton) {
      setShowAdvancedConfigurations(false);
    }
  }, [showAdvancedConfigsButton]);

  useEffect(() => {
    if (initialStreamConfigs?.capacityManagementType !== capacityManagementType) {
      setStreamConfigs({ capacities: [] });
    }
  }, [capacityManagementType]);

  useEffect(() => {
    setNewCapacityType(capacityType);
  }, [capacityType]);

  const installationDurationState = useMemo(() => {
    if (averageInstallationDuration === 0) return "noData";
    if (averageInstallationDuration <= 60) return "underMinute";
    return "overMinute";
  }, [averageInstallationDuration]);

  let appCapacities = useSelector((state) => state.appStreaming.appCapacitiesCTX?.data)?.[applicationId];
  if (appCapacities) appCapacities = snakeToCamel(appCapacities);
  const coveredRegions = appCapacities?.map((cap) => cap.region);
  const selectedCapacities = capacities?.map((cap) => cap.region);

  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const { snapshot_creation_enabled: snapshotCreationEnabled, hot_pool_allowed: hotPoolAllowed } =
    useSelector((state) => state.appStreaming.getVendorAccountCTX?.data?.attributes) || {};
  const maxCapacity = MAX_CAPACITY_BY_VENDOR_TYPE[vendorType];

  useEffect(() => {
    dispatch(getCurrentPlanAPI());
  }, []);

  useEffect(() => {
    if (initialIndividualCapacitySetRef.current) return;
    if (!_.isEqual(initialStreamConfigs?.capacities, capacities)) return;

    setIndividualCapacity(!_.map(capacities, "totalCapacity").every((val, i, arr) => val === arr[0]));
    if (capacityType === CAPACITY_TYPES.balanced) {
      setIndividualCapacity(
        !_.map(capacities, "totalCapacity").every((val, i, arr) => val === arr[0]) ||
          !_.map(capacities, "reserveCapacity").every((val, i, arr) => val === arr[0]),
      );
    }
    initialIndividualCapacitySetRef.current = true;
  }, [capacities]);

  useEffect(() => {
    if (applicationId) dispatch(getAppCapacitiesAPI(applicationId));
  }, [applicationId]);

  useEffect(() => {
    if (containerCount > 1) {
      setPixelStreamingContainerEnabled(true);
    } else {
      setPixelStreamingContainerEnabled(false);
    }
  }, [containerCount]);

  const handleCapacityChange = (type, count, region = null) => {
    const newCapacities = capacities?.map((capacity) => {
      const newCount = () => {
        if ((region && capacity.region === region) || !region) {
          return count;
        }
        return capacity[type];
      };

      if (type === "totalCapacity") {
        return { ...capacity, totalCapacity: newCount() };
      } else if (type === "reserveCapacity") {
        return { ...capacity, reserveCapacity: newCount() };
      }
      return capacity;
    });
    setStreamConfigs({ capacities: newCapacities });
  };

  const handleCapacityTypeChange = (type) => {
    const newCapacities = capacities?.map((capacity) => {
      const newTotalCapacity = () => {
        if (type === CAPACITY_TYPES.always_on) {
          return 0;
        }
        if (capacity.totalCapacity === 0) {
          return MAX_CAPACITY_BY_VENDOR_TYPE.default;
        }
        return capacity.totalCapacity;
      };

      const newReserveCapacity = () => {
        if (type === CAPACITY_TYPES.balanced) {
          return 1;
        }
        return 0;
      };

      return {
        ...capacity,
        capacityType: type,
        totalCapacity: newTotalCapacity(),
        reserveCapacity: newReserveCapacity(),
      };
    });

    setStreamConfigs({ capacities: newCapacities });
  };

  const individualCapacityTableData = () => {
    return capacities?.map((capacity) => {
      const { region, totalCapacity, reserveCapacity } = capacity;
      return {
        id: region,
        region: REGIONS_NAMES[region],
        capacity: (
          <NumberInput
            className="capacity-number-input"
            value={totalCapacity}
            maxInputValue={maxCapacity}
            minInputValue={0}
            onChange={(e) => handleCapacityChange("totalCapacity", e, region)}
            inputMode="numeric"
          />
        ),
        reserve: (
          <NumberInput
            className="capacity-number-input"
            maxInputValue={totalCapacity}
            minInputValue={0}
            value={reserveCapacity}
            onChange={(e) => handleCapacityChange("reserveCapacity", e, region)}
            inputMode="numeric"
          />
        ),
      };
    });
  };

  const regionsText = () => {
    if (fastConnection) {
      if (automatedAreaNames?.length > 1) {
        return translate("configureStream.configurations.regionSelection.regionText", {
          region: translate(`automatedAreas.${automatedAreaNames[0]}`),
          count: automatedAreaNames.length - 1,
        });
      }

      return translate(`automatedAreas.${automatedAreaNames}`);
    }
    if (capacities?.length > 1) {
      return translate("configureStream.configurations.regionSelection.regionText", {
        region: REGIONS_NAMES[capacities?.[0]?.region],
        count: capacities.length - 1,
      });
    }
    return REGIONS_NAMES[capacities?.[0]?.region];
  };

  const CAPACITY_COLUMNS = [
    { name: "Id", weight: 0 },
    { name: translate("configureStream.individualTable.region"), weight: 40 },
  ];

  const setCapacityColumns = () => {
    if (capacityType === CAPACITY_TYPES.balanced) {
      CAPACITY_COLUMNS.push({
        name: translate("configureStream.individualTable.reserve"),
        weight: 40,
      });
    }

    CAPACITY_COLUMNS.push({
      name: translate("configureStream.individualTable.maxCapacity"),
      weight: 40,
    });

    return CAPACITY_COLUMNS;
  };

  const handleRegionClick = (region) => {
    let newCapacities = [...capacities];

    if (capacities.find((cap) => cap.region === region)) {
      if (capacities?.length < 2) return;
      newCapacities = newCapacities.filter((cap) => cap.region !== region);
    } else {
      const capacity = appCapacities.find((cap) => cap.region === region);
      const { totalCapacity, reserveCapacity, capacityType } = capacities[0] || {};
      capacity.totalCapacity = totalCapacity || 5;
      capacity.reserveCapacity = reserveCapacity || 1;
      capacity.capacityType = capacityType || CAPACITY_TYPES.on_demand;
      newCapacities.push(capacity);
    }

    setStreamConfigs({ capacities: newCapacities });
  };

  const { data: streamsRegions } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_REGIONS],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_REGIONS).then((res) => res.data);
    },
  });

  const automatedRegionNames = streamsRegions?.automated_regions?.map((region) => region.attributes.name);
  const automatedAreaNames = [...new Set(automatedRegionNames?.map((region) => AUTOMATED_AREAS[region]))];

  const {
    api: calculateRegionPricingAPI,
    data: regionPricing,
    isLoading: regionPricingLoading,
  } = useAPI({
    type: "post",
    endpoint: APP_STREAMING_API_ENDPOINTS.CALCULATE_REGION_PRICING,
  });

  useEffect(() => {
    if (selectedCapacities && selectedCapacities?.length !== 0) {
      calculateRegionPricingAPI({
        application_id: applicationId,
        regions: selectedCapacities,
        automated: fastConnection,
      });
    } else if (fastConnection) {
      calculateRegionPricingAPI({
        application_id: applicationId,
        regions: automatedAreaNames,
        automated: fastConnection,
        stream_id: streamConfigs.id,
      });
    }

    if (selectedCapacities?.length === 1) {
      setIndividualCapacity(false);
    }
  }, [JSON.stringify(selectedCapacities), fastConnection]);

  const selectedRegionCount = selectedCapacities?.length;
  const minComputeCost = selectedRegionCount > 0 || fastConnection ? regionPricing?.min_compute_cost : null;
  const maxComputeCost = selectedRegionCount > 0 || fastConnection ? regionPricing?.max_compute_cost : null;

  const handleCapacityTypeModalClose = () => {
    if (!capacityWarningConsentApproved && newCapacityType !== CAPACITY_TYPES.on_demand && !showCapacityWarning) {
      setShowCapacityWarning(true);
      return;
    }
    handleCapacityTypeChange(newCapacityType);
    setShowCapacityWarning(false);
    setShowCapacityTypeModal(false);
    setShowStreamCapacityTypeModal(false);

    if (capacityWarningConsent) {
      dispatch(approveCapacityWarningConsent());
    }
  };

  const onCapacityTypeModalClose = () => {
    setShowCapacityTypeModal(false);
    setShowCapacityWarning(false);
    setNewCapacityType(capacityType);
  };

  return (
    <>
      <div className="configurations-basic">
        {!isLinux && hotPoolAllowed && (
          <div className={classnames(["configuration-option dropdown", childStream && "disabled"])}>
            <div>
              <p className="header">{translate("configureStream.configurations.setupType.header")}</p>
              <p className="description">{translate("configureStream.configurations.setupType.description")}</p>
            </div>
            <div className="input-box text-button-container">
              <Dropdown
                underlined
                defaultSelected={capacityManagementType}
                mapFunction={(option) => {
                  return translate(`configureStream.configurations.setupType.options.${option}`);
                }}
                overrideOnClick={() => {
                  setShowStreamCapacityTypeModal(true);
                }}
              />
            </div>
          </div>
        )}

        {!fastConnection && (
          <div className={classnames(["configuration-option gap dropdown", childStream && "disabled"])}>
            <div>
              <div className="with-info-icon">
                <p className="header">{translate("configureStream.configurations.capacityType.header")}</p>
              </div>
              <p className="description">{translate("configureStream.configurations.capacityType.description")}</p>
            </div>
            <div className="input-box">
              <Dropdown
                underlined
                mapFunction={(option) => {
                  return translate(`configureStream.capacityType.${option}`);
                }}
                defaultSelected={fastConnection ? "automated" : streamConfigs?.capacities?.[0]?.capacityType}
                overrideOnClick={() => {
                  if (fastConnection) return;
                  setShowCapacityTypeModal(true);
                }}
                disabled={fastConnection}
              />
            </div>
          </div>
        )}

        <div className={classnames(["configuration-option dropdown", childStream && "disabled"])}>
          <div>
            <p className="header">{translate("configureStream.configurations.regionSelection.header")}</p>
            <p className="description">{translate("configureStream.configurations.regionSelection.description")}</p>
          </div>
          <div className="input-box text-button-container">
            <Dropdown
              underlined
              defaultSelected={regionsText()}
              overrideOnClick={() => {
                setShowChooseRegionModal(true);
              }}
            />
          </div>
        </div>

        {capacityType === CAPACITY_TYPES.balanced && (
          <div
            className={classnames(["configuration-option dropdown", (childStream || individualCapacity) && "disabled"])}
          >
            <div>
              <p className="header">{translate("configureStream.configurations.reserveCapacity.header")}</p>
              <p className="description">{translate("configureStream.configurations.reserveCapacity.description")}</p>
            </div>
            <div className="input-box">
              <NumberInput
                value={individualCapacity ? "" : capacities?.[0]?.reserveCapacity || 0}
                onChange={(e) => handleCapacityChange("reserveCapacity", e)}
                minInputValue={0}
                maxInputValue={capacities?.[0]?.totalCapacity}
                disabled={individualCapacity}
                color="gray-border"
                inputMode="numeric"
              />
              <p>stream machines per region</p>
            </div>
          </div>
        )}
        {capacityType === CAPACITY_TYPES.always_on && (
          <div
            className={classnames(["configuration-option dropdown", (childStream || individualCapacity) && "disabled"])}
          >
            <div className="text-content">
              <p className="header">{translate("configureStream.configurations.totalCapacity.concurrentHeader")}</p>
              <p className="description">{translate("configureStream.configurations.totalCapacity.description")}</p>
            </div>
            <div className="input-box">
              <NumberInput
                inputRef={alwaysOnTotalCapacityInputRef}
                value={individualCapacity ? "" : capacities?.[0]?.totalCapacity || 0}
                minInputValue={0}
                maxInputValue={maxCapacity}
                onChange={(e) => handleCapacityChange("totalCapacity", e)}
                disabled={individualCapacity}
                inputMode="numeric"
              />
              <p>stream machines per region</p>
            </div>
          </div>
        )}

        {!fastConnection && (
          <div className={classnames(["animation-container", showAdvancedConfigurations && "open"])}>
            <div className="animation-content">
              {capacityType !== CAPACITY_TYPES.always_on && (
                <div className={classnames(["configuration-option dropdown", individualCapacity && "disabled"])}>
                  <div className="text-content">
                    <p className="header">
                      {translate("configureStream.configurations.totalCapacity.concurrentHeader")}
                    </p>
                    <p className="description">
                      {translate("configureStream.configurations.totalCapacity.description")}
                    </p>
                  </div>
                  <div className="input-box">
                    <NumberInput
                      value={individualCapacity ? "" : capacities?.[0]?.totalCapacity || 0}
                      minInputValue={0}
                      maxInputValue={maxCapacity}
                      onChange={(e) => handleCapacityChange("totalCapacity", e)}
                      disabled={individualCapacity}
                      inputMode="numeric"
                    />
                    <p>stream machines per region</p>
                  </div>
                </div>
              )}
              {capacities?.length > 1 && (
                <div className="configuration-option">
                  <Switch
                    reverseText
                    className="header"
                    text={translate("configureStream.configurations.individualCapacity.header")}
                    checked={individualCapacity}
                    onChange={(e) => {
                      if (!e) {
                        const maxTotalCapacity = Math.min(
                          Math.max(...capacities.map((cap) => cap.totalCapacity)),
                          maxCapacity,
                        );
                        const maxReserveCapacity = Math.max(...capacities.map((cap) => cap.reserveCapacity));
                        const newCapacities = capacities.map((cap) => {
                          // eslint-disable-next-line no-param-reassign
                          cap.totalCapacity = maxTotalCapacity;
                          // eslint-disable-next-line no-param-reassign
                          cap.reserveCapacity = maxReserveCapacity;
                          return cap;
                        });
                        setStreamConfigs({ capacities: newCapacities });
                      }
                      setIndividualCapacity(e);
                    }}
                  />
                  <p className="description">
                    {translate("configureStream.configurations.individualCapacity.description")}
                  </p>
                </div>
              )}
              {individualCapacity && (
                <Table
                  loading={false}
                  className={classnames(["capacity-table", "basics"])}
                  columns={setCapacityColumns()}
                  data={individualCapacityTableData()}
                />
              )}
            </div>
          </div>
        )}

        {showChooseRegionModal && (
          <Modal
            big
            className="basics-modal"
            topRightIconAction={() => setShowChooseRegionModal(false)}
            topRightIcon="close"
            closeAction={() => setShowChooseRegionModal(false)}
            overrideButton={
              <div className="modal-footer single-button">
                <TextButton
                  text="Done"
                  className="single-button"
                  color="aqua-main"
                  onClick={() => {
                    setShowChooseRegionModal(false);
                  }}
                />
              </div>
            }
          >
            <ChooseRegion
              translate={translate}
              coveredRegions={fastConnection ? automatedAreaNames : coveredRegions}
              selectedCapacities={selectedCapacities}
              onRegionClick={(e) => handleRegionClick(e)}
              minSessionCostPerMinute={minComputeCost}
              maxSessionCostPerMinute={maxComputeCost}
              newDailyCost={regionPricing?.new_daily_datacenter_cost}
              regionPricingLoading={regionPricingLoading}
              hotPoolMap={fastConnection}
            />
          </Modal>
        )}
        {showCapacityTypeModal && (
          <Modal
            big
            className="basics-modal"
            topRightIconAction={onCapacityTypeModalClose}
            topRightIcon="close"
            closeAction={onCapacityTypeModalClose}
            overrideButton={
              <div className={classnames(["modal-footer", showCapacityWarning ? "multiple-button" : "single-button"])}>
                {showCapacityWarning && (
                  <TextButton
                    text="Previous"
                    color="aqua-main"
                    onClick={() => {
                      setShowCapacityWarning(false);
                    }}
                  />
                )}
                <TextButton
                  text="Done"
                  className="single-button"
                  color="aqua-main"
                  onClick={handleCapacityTypeModalClose}
                />
              </div>
            }
          >
            <CapacitySelection
              capacityType={newCapacityType}
              setCapacityType={setNewCapacityType}
              translate={translate}
              showCapacityWarning={showCapacityWarning}
              setShowCapacityWarning={setShowCapacityWarning}
              capacityWarningConsent={capacityWarningConsent}
              setCapacityWarningConsent={setCapacityWarningConsent}
              hideNumberInput
            />
          </Modal>
        )}
        {snapshotCreationEnabled && (
          <div className={classnames(["configuration-option", "gap", childStream && "disabled"])}>
            <div className="with-badge">
              <Switch
                enterprise
                reverseText
                className="header"
                text={translate("configureStream.configurations.boost.header")}
                checked={streamConfigs.boostEnabled}
                onChange={(e) => setStreamConfigs({ boostEnabled: e })}
              />
              <Icon name="lightning" color="aqua-main" small />
              {streamConfigs.boostEnabled && (
                <HoverableTooltip
                  content="Instant Installation may be automatically disabled due to a long period of session inactivity."
                  side="right"
                >
                  <Icon name="info" color="gray-3" smaller />
                </HoverableTooltip>
              )}
            </div>
            <p className="description">
              <Translate
                id={`configureStream.configurations.boost.altDescription.${installationDurationState}`}
                options={{
                  renderInnerHtml: true,
                }}
                data={{ duration: Math.ceil(averageInstallationDuration / 60) }}
              />
            </p>

            {streamConfigs.boostEnabled && (
              <>
                <div className="divider" />
                <p className="alt-description">{translate("configureStream.configurations.boost.description")}</p>
              </>
            )}
          </div>
        )}
        {!fastConnection && !isLinux && (
          <div className="configuration-option">
            <div className="with-icon">
              <Switch
                enterprise
                reverseText
                className="header"
                text={translate("configureStream.configurations.useSatelliteRegions.header")}
                checked={useSatelliteRegions}
                onChange={(e) => {
                  setStreamConfigs({ useSatelliteRegions: e });
                }}
              />
            </div>
            <p className="description">{translate("configureStream.configurations.useSatelliteRegions.description")}</p>
          </div>
        )}
        {!isLinux && canActivatePixelStreaming && !canActivateRenderStreaming && (
          <>
            <div className={classnames(["configuration-option", childStream && "disabled"])}>
              <Switch
                reverseText
                className="header"
                text={translate("configureStream.configurations.pixelStreaming.header")}
                checked={streamConfigs.pixelStreamingEnabled}
                disabled={streamConfigs.sumOfAllUsedCapacities > 0 || streamConfigs.filesEnabled}
                afterContent={
                  streamConfigs.sumOfAllUsedCapacities > 0 && (
                    <HoverableTooltip content="Ensure that you don't have any running Stream Machines." side="right">
                      <Icon name="info" color="gray-3" smaller />
                    </HoverableTooltip>
                  )
                }
                onChange={(e) => {
                  setStreamConfigs({ pixelStreamingEnabled: e });
                  if (e) {
                    setStreamConfigs({
                      resolution: RESOLUTION_TYPES[0],
                      joystick: JOYSTICK_OPTIONS.close,
                      microphone: SOUND_AND_MICROPHONE_OPTIONS.off,
                      keyboardLayout: KEYBOARD_LAYOUTS.us,
                      sound: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start,
                    });
                  }
                }}
              />
              <p className="description">
                <Translate id="configureStream.configurations.pixelStreaming.description" />
              </p>
            </div>
          </>
        )}
        {(streamConfigs.pixelStreamingEnabled || isLinux) && (
          <>
            <div className={classnames(["configuration-option switch-dropdown", childStream && "disabled"])}>
              <div>
                <Switch
                  reverseText
                  text={translate("configureStream.configurations.containerCount.header")}
                  checked={pixelStreamingContainerEnabled}
                  onChange={(e) => {
                    setPixelStreamingContainerEnabled(e);
                    if (!e) {
                      setStreamConfigs({ containerCount: "1" });
                    } else {
                      setStreamConfigs({ containerCount: "2" });
                    }
                  }}
                  disabled={streamConfigs.sumOfAllUsedCapacities > 0}
                />
                <p className="description">{translate("configureStream.configurations.containerCount.description")}</p>
              </div>
              <div className="input-box">
                <Dropdown
                  underlined
                  defaultSelected={streamConfigs.containerCount}
                  options={Object.keys(PIXEL_STREAMING_CONTAINER_COUNTS)}
                  mapFunction={(option) => {
                    return translate(`configureStream.configurations.containerCount.options.${option}`);
                  }}
                  disabled={streamConfigs.sumOfAllUsedCapacities > 0}
                  handleSelectedOptionChange={(option) => {
                    if (!pixelStreamingContainerEnabled) setPixelStreamingContainerEnabled(true);
                    setStreamConfigs({ containerCount: parseInt(option, 10) });
                  }}
                />
              </div>
            </div>
          </>
        )}
        {!isLinux && canActivateRenderStreaming && !canActivatePixelStreaming && (
          <div
            className={classnames([
              "configuration-option",
              childStream && "disabled",
              "render-streaming-option-container",
            ])}
          >
            <Switch
              reverseText
              className="header"
              text={translate("configureStream.configurations.renderStreaming.header")}
              checked={streamConfigs.renderStreamingEnabled}
              disabled={streamConfigs.sumOfAllUsedCapacities > 0 || streamConfigs.filesEnabled}
              afterContent={
                streamConfigs.sumOfAllUsedCapacities > 0 && (
                  <HoverableTooltip content="Ensure that you don't have any running Stream Machines." side="right">
                    <Icon name="info" color="gray-3" smaller />
                  </HoverableTooltip>
                )
              }
              onChange={(e) => {
                setStreamConfigs({ renderStreamingEnabled: e });
                if (e) {
                  setStreamConfigs({
                    resolution: RESOLUTION_TYPES[0],
                    joystick: JOYSTICK_OPTIONS.close,
                    microphone: SOUND_AND_MICROPHONE_OPTIONS.off,
                    keyboardLayout: KEYBOARD_LAYOUTS.us,
                    sound: SOUND_AND_MICROPHONE_OPTIONS.activate_on_start,
                  });
                  if (streamConfigs.collectApplicationLogs) {
                    setStreamConfigs({ collectApplicationLogs: false });
                  }
                }
              }}
              isAlpha
            />
            <p className="description">
              <Translate id="configureStream.configurations.renderStreaming.description" />
            </p>

            {streamConfigs.renderStreamingEnabled && (
              <>
                <div className="divider" />
                <p className="alt-description" style={{ width: "100%" }}>
                  <Translate
                    id="configureStream.configurations.renderStreaming.enabledInfo"
                    options={{ renderInnerHtml: true }}
                    data={{ documentationLink: "https://docs.vagon.io/streams/guidelines/unity-render-streaming" }}
                  />
                </p>
              </>
            )}
          </div>
        )}
        {showAdvancedConfigsButton && (
          <DivButton
            className="advanced-configuration"
            disabled={childStream}
            onClick={() => {
              setShowAdvancedConfigurations((prev) => !prev);
            }}
          >
            <p>Advanced Configs</p>
            <Icon name={showAdvancedConfigurations ? "caret-up" : "caret-down"} color="aqua-main-stroke" />
          </DivButton>
        )}

        {showStreamCapacityTypeModal && (
          <StreamCapacityTypeModal
            automatedAreaNames={automatedAreaNames}
            translate={translate}
            selectedApp={application || {}}
            capacities={capacities}
            capacityManagement={capacityManagementType}
            setCapacityManagement={(e) => setStreamConfigs({ capacityManagementType: e })}
            onClose={() => {
              setStreamConfigs({
                capacities: initialStreamConfigs.capacities,
                capacityManagementType: initialStreamConfigs.capacityManagementType,
              });
              setShowStreamCapacityTypeModal(false);
            }}
            onFinish={() => {
              setShowStreamCapacityTypeModal(false);
            }}
            coveredRegions={coveredRegions}
            selectedCapacities={selectedCapacities}
            onRegionClick={(e) => handleRegionClick(e)}
            minSessionCostPerMinute={minComputeCost}
            maxSessionCostPerMinute={maxComputeCost}
            newDailyCost={regionPricing?.new_daily_datacenter_cost}
            regionPricingLoading={regionPricingLoading}
            capacityType={newCapacityType}
            setCapacityType={setNewCapacityType}
            showCapacityWarning={showCapacityWarning}
            setShowCapacityWarning={setShowCapacityWarning}
            capacityWarningConsent={capacityWarningConsent}
            setCapacityWarningConsent={setCapacityWarningConsent}
            hideNumberInput
            handleCapacityTypeModalClose={handleCapacityTypeModalClose}
            setShowSubscriptionRequestModal={setShowSubscriptionRequestModal}
          />
        )}

        {showSubscriptionRequestModal && (
          <SubscriptionRequestModal
            translate={translate}
            handleClose={() => {
              dispatch(getVendorAccountAPI());
              setShowSubscriptionRequestModal(false);
            }}
          />
        )}
      </div>
    </>
  );
};

export default BasicsStep;
