import React, { useRef, useState, useEffect } from "react";
import { ExpandableTable, IconButton, HoverableTooltip, Icon, Divider } from "UI";
import { useSelector } from "react-redux";
import { useQuery } from "@tanstack/react-query";
import { apiGenerator, classnames, useAPI } from "Utils";
import { Translate } from "react-localize-redux";
import {
  BG_STYLE_BY_APP_STATUS,
  APPLICATION_STATUS,
  VENDOR_TYPES,
  STREAM_SCHEDULE_TYPES,
  ICON_COLOR_BY_STREAM_STATUS,
  AUTOMATED_AREAS,
} from "Constants/AppStreaming.constants";
import AppMenuPopUp from "Components/AppStreaming/Home/AppMenuPopUp/AppMenuPopUp.component";
import { CAPACITY_MANAGEMENT_TYPES, REGIONS_NAMES, REQUEST_STATUS } from "Constants/global.constants";
import { useHistory } from "react-router-dom";
import { APP_STREAMING_API_ENDPOINTS } from "Constants/api.constants";
import { convertDateFormat, convertToSelectedTimeZone } from "Utils/Helpers/functions.helpers";
import routes from "../../../../Constants/Route.constants";

import "./StreamTable.styles.scss";

const RUN_METHOD_FRIENDLY_NAME = {
  on_demand: "Cost Optimized",
  always_on: "Availability Optimized",
  balanced: "Balanced",
};
const AUTO_TURN_OFF_OPTIONS = {
  40: "IMMEDIATELY",
  900: "AFTER_15_MINUTES",
  3600: "AFTER_1_HOUR",
  10800: "AFTER_3_HOURS",
  21600: "AFTER_6_HOURS",
  0: "NO",
};

const SCHEDULE_TYPE_FRIENDLY_NAME = {
  always_on: "Always Available",
  time_range: "Time Range",
  work_days: "Working Hours",
};

const getAutoTurnOffData = (autoTurnoffDuration, translate) => {
  const isInRange = AUTO_TURN_OFF_OPTIONS[autoTurnoffDuration];
  if (isInRange)
    return translate(`appStreamingStreams.autoTurnOffOptions.${AUTO_TURN_OFF_OPTIONS[autoTurnoffDuration]}`);
  const hours = autoTurnoffDuration / 3600;
  const minutes = autoTurnoffDuration / 60;
  if (hours >= 1) return translate("appStreamingStreams.autoTurnOffOptions.hours", { autoTurnoffTime: hours });
  return translate("appStreamingStreams.autoTurnOffOptions.minutes", { autoTurnoffTime: minutes });
};

const StreamInfo = ({ stream, app, userTimeZone, translate }) => {
  const {
    capacity_management_type: capacityManagementType,
    start_date: startAt,
    end_date: endAt,
    capacities,
  } = stream.attributes || {};

  const { data: streamsRegions } = useQuery({
    queryKey: [APP_STREAMING_API_ENDPOINTS.GET_REGIONS],
    queryFn: () => {
      return apiGenerator("get")(APP_STREAMING_API_ENDPOINTS.GET_REGIONS).then((res) => res.data);
    },
  });

  const automatedRegionNames = streamsRegions?.automated_regions?.map((region) => region.attributes.name);
  const automatedAreaNames = [
    ...new Set(automatedRegionNames?.map((region) => translate(`automatedAreas.${AUTOMATED_AREAS[region]}`))),
  ];

  const fastConnection = capacityManagementType === CAPACITY_MANAGEMENT_TYPES.fast_connection;
  const regions = fastConnection
    ? automatedAreaNames
    : capacities.map((capacity) => REGIONS_NAMES[capacity.attributes.region]);

  return (
    <>
      <div>
        <h4>{translate("appStreamingStreams.streamTable.infra.header")}</h4>
        <Translate
          id="appStreamingStreams.streamTable.infra.regions"
          options={{
            renderInnerHtml: true,
          }}
          data={{
            regions: regions.join(", "),
          }}
        />
        <Translate
          id="appStreamingStreams.streamTable.infra.user"
          options={{
            renderInnerHtml: true,
          }}
          data={{
            active: stream.attributes.sum_of_connected_machines,
            total: stream.attributes.sum_of_all_used_capacities,
          }}
        />
        <Translate
          id="appStreamingStreams.streamTable.infra.performance"
          options={{
            renderInnerHtml: true,
          }}
          data={{ performance: app.attributes.default_machine_type?.attributes?.stream_friendly_name || "" }}
        />
      </div>
      <div>
        <h4>{translate("appStreamingStreams.streamTable.config.header")}</h4>
        {stream?.attributes?.schedule_type === STREAM_SCHEDULE_TYPES.time_range && startAt && endAt ? (
          <Translate
            id="appStreamingStreams.streamTable.config.schedule"
            options={{
              renderInnerHtml: true,
            }}
            data={{
              from: convertToSelectedTimeZone(
                `${convertDateFormat(startAt.substring(0, 10))} ${startAt.substring(
                  startAt.indexOf("T") + 1,
                  startAt.indexOf("T") + 6,
                )}`,
                userTimeZone,
              ),
              to: convertToSelectedTimeZone(
                `${convertDateFormat(endAt.substring(0, 10))} ${endAt.substring(
                  endAt.indexOf("T") + 1,
                  endAt.indexOf("T") + 6,
                )}`,
                userTimeZone,
              ),
            }}
          />
        ) : (
          <Translate
            id="appStreamingStreams.streamTable.config.scheduled"
            options={{
              renderInnerHtml: true,
            }}
            data={{ schedule_type: SCHEDULE_TYPE_FRIENDLY_NAME[stream?.attributes?.schedule_type] }}
          />
        )}
        <Translate
          id="appStreamingStreams.streamTable.config.runMethod"
          options={{
            renderInnerHtml: true,
          }}
          data={{
            runMethod: fastConnection
              ? "Automated"
              : RUN_METHOD_FRIENDLY_NAME[stream?.attributes?.capacities?.[0]?.attributes?.capacity_type] || "",
          }}
        />
        <Translate
          id="appStreamingStreams.streamTable.config.autoTurnOff"
          options={{
            renderInnerHtml: true,
          }}
          data={{
            autoTurnOff: getAutoTurnOffData(stream.attributes.auto_turn_off_duration, translate),
          }}
        />
      </div>
    </>
  );
};

const StreamActionButton = (props) => {
  const { iconName, action, content, buttonRef, disabled, color } = props;

  return (
    <HoverableTooltip content={content}>
      <IconButton
        buttonRef={buttonRef}
        name={iconName}
        className="stream-table-action-button"
        onClick={action}
        disabled={disabled}
        color={color}
      />
    </HoverableTooltip>
  );
};

const LinkCell = ({
  hasChildStreams,
  editingStream,
  editLinkRef,
  urlDomain,
  uid,
  link,
  setLink,
  streamID,
  streamLinkError,
  streamBudgetStatus,
  connectionLink,
  translate,
}) => {
  if (hasChildStreams)
    return (
      <>
        {streamBudgetStatus === "fulfilled" && (
          <HoverableTooltip content="Stream Budget Reached" side="top" className="boosting-tooltip">
            <Icon name="warning" className="stream-budget-info" color="red" />
          </HoverableTooltip>
        )}
      </>
    );

  if (editingStream) {
    return (
      <div className="edit-stream-link">
        <p>{urlDomain}</p>
        <input
          ref={editLinkRef}
          className={classnames(["stream-link-input", editingStream && "editing"])}
          type="text"
          maxLength="30"
          placeholder={uid}
          disabled={!editingStream}
          value={link}
          onChange={(e) => {
            setLink(e.target.value);
          }}
        />

        {streamID === streamLinkError.streamID && (
          <HoverableTooltip
            side="right"
            className="error-tooltip"
            content={translate(`appStreamingStreams.streamTable.linkEdit.errors.${streamLinkError.errorCode}`)}
          >
            <Icon smaller className="link-error-icon" name="error" color="purple-main" />
          </HoverableTooltip>
        )}
      </div>
    );
  }

  return (
    <>
      {streamBudgetStatus === "fulfilled" && (
        <HoverableTooltip content="Stream Budget Reached" side="top" className="boosting-tooltip">
          <Icon name="warning" className="stream-budget-info" color="red" />
        </HoverableTooltip>
      )}
      <p className="stream-link">{connectionLink}</p>
    </>
  );
};

const StreamTable = ({
  app,
  selectedStream,
  setSelectedStream,
  setSelectedApp,
  setShowDeleteStreamConfirmationModal,
  setShowShareStreamModal,
  pauseStreamAPI,
  pauseStreamCTX,
  updateStreamCTX,
  updateStreamAPI,
  getStreamsAPI,
  showStreamsOnboardingModal,
  translate,
}) => {
  const listContainerRef = useRef(null);
  const [streamActionsPopUpPosition, setStreamActionsPopUpPosition] = useState(null);
  const [linkEditingStreamID, setLinkEditingStreamID] = useState(null);
  const [inProgress, setInProgress] = useState(null);
  const [streamLinkError, setStreamLinkError] = useState({ streamID: null, errorCode: null });
  const [link, setLink] = useState("");
  const vendorType = useSelector((state) => state.appStreaming.vendorType);
  const isVendorPro = vendorType === VENDOR_TYPES.pro || vendorType === VENDOR_TYPES.enterprise;
  const appBudgetStatus = app?.attributes?.budget?.attributes?.status;
  const editLinkRefs = {};
  const { time_zone: userTimeZone } = useSelector((state) => state?.account?.account?.attributes) || {};

  const selectedStreamBoosting = selectedStream?.attributes?.boost_enabled && !selectedStream?.attributes?.boost_ready;

  const history = useHistory();

  const { api: updateStreamLinkAPI } = useAPI({
    type: "put",
    endpoint: APP_STREAMING_API_ENDPOINTS.UPDATE_STREAM_LINK(linkEditingStreamID),
    onSuccess: () => {
      setLinkEditingStreamID(null);
      getStreamsAPI();
      setStreamLinkError({});
      setLink("");
    },
    onErr: (err) => {
      setStreamLinkError({ streamID: linkEditingStreamID, errorCode: err.client_code });
    },
  });

  useEffect(() => {
    if (pauseStreamCTX.status === REQUEST_STATUS.PENDING || updateStreamCTX.status === REQUEST_STATUS.PENDING) {
      setInProgress(selectedStream.id);
      setTimeout(() => {
        getStreamsAPI();
        setInProgress(null);
      }, 10000);
    }
  }, [pauseStreamCTX.status, updateStreamCTX.status]);

  const handleKeyDown = (e) => {
    if (e.key === "Escape") {
      setLinkEditingStreamID(null);
      setStreamLinkError({});
      setLink("");
    }

    if (e.key === "Enter") {
      updateStreamLinkAPI({ link });
    }
  };

  useEffect(() => {
    window.addEventListener("keydown", handleKeyDown);

    return () => window.removeEventListener("keydown", handleKeyDown);
  }, [link]);

  const handleMoreButtonClick = (event, item) => {
    if (!streamActionsPopUpPosition) event.stopPropagation();
    event.preventDefault();
    setSelectedStream(item);
    if (selectedStream || item) {
      setStreamActionsPopUpPosition({ x: event.clientX, y: event.clientY, top: event.clientY });
    }
  };

  const capacityColHeader = () => {
    return (
      <div className="capacity-col-header">
        {translate("appStreamingStreams.streamTable.capacity")}
        <HoverableTooltip content="Active Visitor / Running Machine" side="top" align="end">
          <Icon name="info" color="gray-4" smaller />
        </HoverableTooltip>
      </div>
    );
  };

  const columns = [
    { name: translate("appStreamingStreams.streamTable.name"), weight: 20 },
    { name: translate("appStreamingStreams.streamTable.link"), weight: 60 },
    { name: "capacity", weight: 15, override: capacityColHeader() },
    { name: "buttons", weight: 10, hide: true },
  ];

  useEffect(() => {
    if (linkEditingStreamID) editLinkRefs[linkEditingStreamID].current.focus();
  }, [linkEditingStreamID]);

  const data = app.attributes.streams
    .flatMap((stream) => {
      return [stream, ...stream.attributes.child_streams];
    })
    // .filter((stream) => stream.attributes.sum_of_all_capacities !== 0)
    .map((stream, index) => {
      if (!stream) return null;
      const streamID = stream.id;
      const pendingState = inProgress === streamID;
      const editingStream = linkEditingStreamID === streamID;
      const editLinkRef = React.createRef();
      editLinkRefs[streamID] = editLinkRef;
      const streamActive = stream.attributes.status === APPLICATION_STATUS.ACTIVE;
      const {
        boost_enabled: boostEnabled,
        boost_ready: boostReady,
        pixel_streaming_enabled: pixelStreamingEnabled,
        render_streaming_enabled: renderStreamingEnabled,
        budget,
        budget_limit_reached: budgetLimitReached,
        highlightClass,
        parent_stream_id: parentStreamID,
        child_streams: childStreams,
        url_domain: urlDomain,
        uid,
        connection_link: connectionLink,
        name,
        container_count: containerCount,
      } = stream.attributes;

      const boosting = boostEnabled && !boostReady;
      const streamBudgetStatus = budget?.attributes?.status;
      const hasChildStreams = childStreams?.length > 0;

      const cancelEditLink = () => {
        setLinkEditingStreamID(null);
        setStreamLinkError({});
        setLink("");
      };

      const submitEditLink = () => {
        updateStreamLinkAPI({ link: link || stream.attributes.uid });
        setLink("");
      };

      return {
        name: (
          <div className="name-cell">
            <div className="name-cell-icons">
              {!parentStreamID &&
                (boostReady ? (
                  <HoverableTooltip content="Instant Installation">
                    <Icon
                      name="lightning"
                      className={classnames(["lightning-status", pendingState && "animated"])}
                      color={
                        ICON_COLOR_BY_STREAM_STATUS[`${pendingState ? "pending_changes" : stream.attributes.status}`]
                      }
                    />
                  </HoverableTooltip>
                ) : (
                  <div
                    className={classnames(["app-status", pendingState && "animated"])}
                    style={BG_STYLE_BY_APP_STATUS[`${pendingState ? "pending_changes" : stream.attributes.status}`]}
                  />
                ))}

              {pixelStreamingEnabled && (
                <HoverableTooltip content="Pixel Streaming">
                  <Icon name="pixel-streaming" className={classnames(["pixel-streaming-status"])} color="grey" />
                </HoverableTooltip>
              )}
              {renderStreamingEnabled && (
                <HoverableTooltip content="Render Streaming">
                  <Icon name="render-streaming" className="pixel-streaming-status" color="grey" />
                </HoverableTooltip>
              )}
              {containerCount === 2 && (
                <HoverableTooltip content="2 Tenants per Machine">
                  <Icon name="two-squares" className="pixel-streaming-container-count" color="grey" />
                </HoverableTooltip>
              )}
              {containerCount === 4 && (
                <HoverableTooltip content="4 Tenants per Machine">
                  <Icon name="four-squares" className="pixel-streaming-container-count" color="grey" />
                </HoverableTooltip>
              )}
            </div>

            <p>{name}</p>
          </div>
        ),
        link:
          boosting && !hasChildStreams ? (
            <div className={classnames(["boosting-badge", index % 2 === 0 && "even"])}>
              <Icon name="lightning" className="lightning-status boosting" />
              <p>Optimizing Application</p>
              <p>&nbsp;</p>
              <span>- Link will be visible when the Stream is ready.</span>
            </div>
          ) : (
            <LinkCell
              hasChildStreams={hasChildStreams}
              editingStream={editingStream}
              editLinkRef={editLinkRef}
              urlDomain={urlDomain}
              uid={uid}
              link={link}
              setLink={setLink}
              streamID={streamID}
              streamLinkError={streamLinkError}
              boosting={boosting}
              streamBudgetStatus={streamBudgetStatus}
              connectionLink={connectionLink}
              translate={translate}
            />
          ),
        capacity:
          !parentStreamID &&
          `${stream.attributes.sum_of_connected_machines} / ${stream.attributes.sum_of_all_used_capacities}`,
        key: stream.id,
        buttons: (
          <div className="icon-buttons">
            {linkEditingStreamID !== stream.id && (
              <>
                {!parentStreamID && (
                  <StreamActionButton
                    iconName={stream.attributes.status === APPLICATION_STATUS.ACTIVE ? "stream-pause" : "stream-play"}
                    action={() => {
                      if (showStreamsOnboardingModal) {
                        return;
                      }
                      setSelectedStream(stream);
                      if (streamActive) pauseStreamAPI(stream.id);
                      else updateStreamAPI(stream.id, "start");
                    }}
                    content={translate(`appStreamingIconButtons.${streamActive ? "pause" : "start"}`)}
                    disabled={pendingState || budgetLimitReached}
                  />
                )}
                {(!childStreams || childStreams.length === 0) && (
                  <>
                    <StreamActionButton
                      iconName="share"
                      action={() => {
                        if (!showStreamsOnboardingModal) {
                          setSelectedApp(app);
                          setSelectedStream(stream);
                          setShowShareStreamModal(true);
                        }
                      }}
                      content={translate("appStreamingIconButtons.share")}
                      disabled={pendingState}
                    />
                  </>
                )}
                {parentStreamID ? (
                  <StreamActionButton
                    iconName="pen"
                    action={() => {
                      if (!showStreamsOnboardingModal) {
                        setSelectedStream(stream);
                        setLinkEditingStreamID(stream.id);
                        setStreamActionsPopUpPosition(null);
                      }
                    }}
                    content={translate("appStreamingIconButtons.editLink")}
                    disabled={pendingState}
                    color="gray-4"
                  />
                ) : (
                  <StreamActionButton
                    iconName="more"
                    action={(event) => !showStreamsOnboardingModal && handleMoreButtonClick(event, stream)}
                    content={translate("appStreamingIconButtons.more")}
                    disabled={pendingState}
                    color="gray-4"
                  />
                )}
                <StreamActionButton
                  iconName="settings"
                  action={() => {
                    if (!showStreamsOnboardingModal) {
                      history.push({
                        pathname: routes.appStreamingConfigureStream,
                        state: { streamID: stream.attributes.id },
                      });
                    }
                  }}
                  content={translate("appStreamingIconButtons.configure")}
                  disabled={pendingState}
                  color="gray-4"
                />
              </>
            )}
            {linkEditingStreamID === stream.id && (
              <>
                <IconButton small className="edit-link-icon" name="close" color="gray-4" onClick={cancelEditLink} />
                <div className="vertical-divider" color="gray-4" />
                <IconButton
                  small
                  className="edit-link-icon"
                  name="stream-check"
                  color="gray-4"
                  onClick={submitEditLink}
                />
              </>
            )}
          </div>
        ),
        expandableContent: <StreamInfo stream={stream} app={app} userTimeZone={userTimeZone} translate={translate} />,
        caret: !parentStreamID,
        highlightClass,
      };
    });

  return (
    <div className="stream-table" key={app.id} ref={listContainerRef}>
      <div className="app-name">
        <h3>{app.attributes.name}</h3>
        {appBudgetStatus === "fulfilled" && (
          <HoverableTooltip content="Application Budget Reached" side="top" className="boosting-tooltip">
            <Icon name="warning" className="stream-app-budget-info" color="red" />
          </HoverableTooltip>
        )}
      </div>
      <div className="stream-table-container">
        <ExpandableTable columns={columns} data={data} disabled={showStreamsOnboardingModal} />
      </div>
      {streamActionsPopUpPosition && (
        <AppMenuPopUp
          setShowPopUpMenu={() => {
            setStreamActionsPopUpPosition(null);
          }}
          className="app-list-pop-up-menu stream-list-pop-up-menu"
          content={[
            {
              key: "date-container",
              disableHover: true,
              text: (
                <>
                  <div className="date-container">
                    <p>Created at</p>
                    {selectedStream.attributes.created_at
                      ? convertToSelectedTimeZone(
                          `${convertDateFormat(
                            selectedStream.attributes.created_at.substring(0, 10),
                          )} ${selectedStream.attributes.created_at.substring(
                            selectedStream.attributes.created_at.indexOf("T") + 1,
                            selectedStream.attributes.created_at.indexOf("T") + 6,
                          )}`,
                          userTimeZone,
                        )
                      : ""}
                  </div>
                  <Divider />
                </>
              ),
            },
            {
              key: "url-update",
              text: (
                <div
                  className={classnames(["update-url-button"])}
                  style={{ display: "flex", gap: "0.6rem", alignItems: "center" }}
                >
                  <Icon name="pencil" color="gray-4" small className="stream-table-action-button" />
                  Update URL
                </div>
              ),
              onClick: () => {
                setLinkEditingStreamID(selectedStream.id);
                setStreamActionsPopUpPosition(null);
              },
              disabled: !isVendorPro || selectedStreamBoosting,
              hide: selectedStream.attributes.child_streams?.length > 0,
            },
            {
              key: "delete-link",
              text: (
                <div style={{ display: "flex", gap: "0.6rem", alignItems: "center" }}>
                  <Icon name="trash" color="gray-4-fill" small className="stream-table-action-button" />
                  {translate("appStreamingStreams.streamTable.popUpMenu.delete")}
                </div>
              ),
              onClick: (event) => {
                event.stopPropagation();
                setStreamActionsPopUpPosition(null);
                setShowDeleteStreamConfirmationModal(true);
              },
              disabled: inProgress === selectedStream.id,
            },
          ]}
          popUpPosition={streamActionsPopUpPosition}
          referencePosRef={listContainerRef}
        />
      )}
    </div>
  );
};

export default StreamTable;
